import React, { Component } from 'react';
import "./Hero.css?__remix_sideEffect__";
import ReactPlayer from 'react-player';
import ArticleSocialShare from '../ArticleSocialShare/ArticleSocialShare';
import playIcon from '../../images/play-icon.svg';

export default class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = { playing: false };
  }
  render() {
    let {
      eyebrow,
      textColor,
      backgroundColor,
      image,
      headline,
      videoUrl,
      copy
    } = this.props;

    // Analytics.
    let trackClickOptions = {
      video: {
        event_action: "Home content click",
        event_category: "Hero Video",
        event_label: `Home content click: ${videoUrl}`
      }
    };

    return (
      <div className={`hero hero--${backgroundColor}`}>
        <div className={`hero__content ${textColor}`}>
          <div>
            <p className="eyebrow">{eyebrow ?? ''}</p>
            <h1 className="headline headline--lg hero__headline">
              {headline}
            </h1>
            <div className="hero__copy copy copy--intro"
            dangerouslySetInnerHTML={{ __html: copy ?? '' }}>
            </div>
          </div>
          <ArticleSocialShare borderColor={textColor} />
        </div>

        <div
          className={`hero__img-ctnr ${
          this.state.playing && videoUrl ? 'playing' : ''
          }`}
          onClick={() => {
            this.setState({ playing: true });
          }}>

          {image &&
          <div
            className={`hero__img ${videoUrl ? 'has-video' : ''}`}
            style={{
              backgroundImage: `url(${image.dataOriginal})`,
              backgroundSize: 'cover',
              backgroundPosition: image.position
            }} />}


          {videoUrl &&
          <React.Fragment>
              <ReactPlayer
              width={'100%'}
              height={'100%'}
              url={`${videoUrl}`}
              playing={this.state.playing}
              playsinline={false}
              className={'hero__video react-player'}
              config={{
                youtube: {
                  playerVars: { rel: 0, controls: 1, playsinline: 0 },
                  embedOptions: {
                    host: 'https://www.youtube-nocookie.com'
                  }
                }
              }} />

              {image && <img
              src={playIcon}
              className="hero__play-btn play-btn"
              alt="Play video"
              data-track-click={JSON.stringify(trackClickOptions.video)} />}

            </React.Fragment>}

        </div>
      </div>);

  }
}