import React, { Component } from "react";
import "./CookieModal.css?__remix_sideEffect__";



export default class CookieModal extends Component {
  constructor(props) {
    super(props);
    if (typeof document !== "undefined" && props.modalOpen) {
      document.body.classList.add("modal-open");
    }
  }

  render() {
    let { countryCode, toggleSettings, modalOpen, handleOk, copy } = this.props;

    if (typeof document !== "undefined" && modalOpen) {
      document.body.classList.add("modal-open");
    }

    let base = "";
    if (typeof window !== "undefined") {
      base = window.location.host === "www.nikesb.com" ? "https://agreementservice.svs.nike.com" : "https://agreement.test.svs.nike.com";
    } else {
      base = process.env.BASE_URL === "https://www.nikesb.com" ? "https://agreementservice.svs.nike.com" : "https://agreement.test.svs.nike.com";
    }
    let privacyLink = `${base}/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.nikesb&country=${countryCode}&requestType=styled`;
    let bannerClasses = `cookie-modal ${modalOpen ? 'open' : 'closed'}`;

    return (
      <div className={bannerClasses}>
        <div className="cookie-modal__ctnt">
          <h2 className="yellow headline headline--lg headline--highlight headline--highlight-blue">{copy.cookieSettings}</h2>
          <p className="cookie-modal__copy copy copy--sans">
            {copy.modalLanguage.map((component, i) => {
              let key = `banner:${i}`;
              switch (component.block) {
                case "copy":
                  return <React.Fragment key={key}>{component.copy}</React.Fragment>;
                case "privacyLink":
                  return <a href={privacyLink} className="link--inline" rel="noopener noreferrer" target="_blank" key={key}>{component.copy}</a>;
                case "settings":
                  return <button onClick={toggleSettings} className="link--inline" key={key}>{component.copy}</button>;
                default:
                  return false;
              }
            })}
          </p>
          <button onClick={toggleSettings} className="cookie-modal__btn cookie-modal__btn--black">{copy.moreInfo}</button>
          <button onClick={handleOk} className="cookie-modal__btn">{copy.bannerOk}</button>

          <p className="copy copy--sans">
            {copy.changePreference.map((component, i) => {
              let key = `settings:${i}`;
              switch (component.block) {
                case "copy":
                  return <React.Fragment key={key}>{component.copy}</React.Fragment>;
                case "privacyLink":
                  return <a className="link--inline" key={key} href={privacyLink} rel="noopener noreferrer" target="_blank">{component.copy}</a>;
                default:
                  return false;
              }
            })}
          </p>
        </div>
      </div>);

  }
}