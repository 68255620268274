import React, { Component } from "react";
import "./CookieBanner.css?__remix_sideEffect__";



export default class CookieBanner extends Component {
  render() {
    let { countryCode, toggleSettings, bannerOpen, handleOk, copy } = this.props;

    let base = "";
    if (typeof window !== "undefined") {
      base = window.location.host === "www.nikesb.com" ? "https://agreementservice.svs.nike.com" : "https://agreement.test.svs.nike.com";
    } else {
      base = process.env.BASE_URL === "https://www.nikesb.com" ? "https://agreementservice.svs.nike.com" : "https://agreement.test.svs.nike.com";
    }
    let privacyLink = `${base}/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.nikesb&country=${countryCode}&requestType=styled`;
    let bannerClasses = `cookie-banner ${bannerOpen ? 'open' : 'closed'}`;

    return (
      <div className={bannerClasses}>
        <p className="cookie-banner__copy copy copy--sans">
          {copy.bannerLanguage.map((component, i) => {
            let key = `banner:${i}`;
            switch (component.block) {
              case "copy":
                return <React.Fragment key={key}>{component.copy}</React.Fragment>;
              case "privacyLink":
                return <a href={privacyLink} target="_blank" rel="noopener noreferrer" key={key}>{component.copy}</a>;
              case "settings":
                return <button onClick={toggleSettings} className="cookie-banner__link" key={key}>{component.copy}</button>;
              default:
                return false;
            }
          })}
        </p>
        <button onClick={handleOk} className="cookie-banner__btn">{copy.bannerOk}</button>
      </div>);

  }
}