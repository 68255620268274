import React, { Component } from 'react';
import "./NavBar.css?__remix_sideEffect__";
import NavMobile from './NavMobile';
import { Link } from 'react-router-dom';
import logo from '../../images/nikesb-logo.svg';
import hamburgerIcon from '../../images/icon-hamburger.svg';

export default class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastScrollPos: 0,
      logoWidthClass: 'large',
      modalOpen: false,
      additionalNavItems: null
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }
  onScroll = () => {
    if (this.state.lastScrollPos < window.pageYOffset) {
      this.setState({ logoWidthClass: 'small' });
    } else
    if (window.pageYOffset === 0) {
      this.setState({ logoWidthClass: 'large' });
    }
  };
  openModal = () => {
    this.setState({ modalOpen: true });
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };
  getApplicationNode = () => {
    return document.getElementById('root');
  };
  render() {
    let path = this.props.match;
    let style = this.props.style;
    let { logoWidthClass } = this.state;

    let classes = path === '/shops' ? 'nav nav--small nav--static' : `nav nav--${logoWidthClass}`;
    let logoClasses = path === '/shops' ? 'nav__logo nav__logo--small' : `nav__logo nav__logo--${logoWidthClass}`;

    const navLinks = this.props.navLinks ?? [];

    return (
      <React.Fragment>
        {path !== '/shops' &&
        <div className="nav-placeholder" />}

        <nav
          className={classes}
          style={{ ...style, width: '100%' }}>

          <div className="nav__ctnr ctnr">
            <div className="nav__left">
              <Link
                to="https://nikesb.com"
                className="nav__logo__parent">

                <img
                  alt=""
                  className={logoClasses}
                  src={logo} />

              </Link>

              <ul className="nav__list">
                {navLinks && navLinks.map((navLink, i) => {
                  return (
                    <li className="nav__list-item" key={`nav-item${i}`}>
                      <Link className="nav__highlight" to={navLink.link}>
                            <span className={`${path && path === navLink.link ? 'active' : ''}`}>
                              {navLink.title}
                            </span>
                      </Link>
                    </li>);

                })}
              </ul>
            </div>

            <img
              className={`nav__burger-icon nav__burger-icon--${logoWidthClass}`}
              src={hamburgerIcon}
              alt=""
              onClick={this.openModal} />

          </div>

          <div style={{ width: 0, height: 0, position: 'absolute' }}>
            {this.state.modalOpen ?
            <NavMobile
              getApplicationNode={this.getApplicationNode}
              closeModal={this.closeModal}
              navLinks={navLinks} /> :


            false}

          </div>
        </nav>
      </React.Fragment>);

  }
}