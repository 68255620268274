import React, { Component } from 'react';
import "./ImageWithCopy.css?__remix_sideEffect__";
import { Link } from 'react-router-dom';



export default class ImageWithCopy extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false
    };
  }
  changeModalClosed() {
    this.setState({ modalOpen: false });
  }
  changeModalOpen() {
    this.setState({ modalOpen: true });
  }
  render() {
    let { borderColor, textColor = 'black', copy, image, imageCopyAlignment, slug } = this.props;
    const assetOrder = imageCopyAlignment ? imageCopyAlignment : '';

    return (
      // Check flex css change order prop
      <div className={`img-copy ctnr ${assetOrder}`}>
        <div className={`img-copy__group ${assetOrder}`}>
          <div className="img-copy__img"
          dangerouslySetInnerHTML={{ __html: image.asset }}>
          </div>
        </div>
        <div className={`img-copy__copy img-copy__copy--${borderColor}`}>
          <div className="column" />
          <div className={`copy-block copy copy--intro copy-url ${textColor}`} dangerouslySetInnerHTML={{ __html: copy }} />
          <Link className={'link copy'} to={`/${slug}`}>View the full comic</Link>
        </div>
      </div>);

  }
}