import React, { Component } from 'react';
import "./NavMobile.css?__remix_sideEffect__";
import AriaModal from 'react-aria-modal';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import closeIcon from '../../images/icon-close.svg';
import facebookIcon from '../../images/Social_Facebook_Black.svg';
import instagramIcon from '../../images/Social_Instagram_Black.svg';
import twitterIcon from '../../images/Social_Twitter_Black.svg';
import { LayoutGroup, motion } from 'framer-motion';

const variants = {
  preEnter: {
    opacity: 0,
    height: '0px'
  },
  enter: {
    opacity: 1,
    height: '200px',
    transition: {
      height: { type: 'spring', stiffness: 1000, damping: 20 },
      default: { duration: 900 }
    },
    delay: 400
  }
};

export default class NavMobile extends Component {
  constructor(props) {
    super(props);
    this.state = { open: true };
  }
  render() {
    const navLinks = this.props.navLinks ?? [];

    return (
      <CSSTransition
        in={this.state.open}
        appear
        classNames="overlay overlay"
        key={'mobile-nav-overlay'}
        timeout={300}>

        <AriaModal
          titleText="Nike SB Mobile Navigation"
          initialFocus="#close"
          getApplicationNode={this.props.getApplicationNode}
          underlayStyle={{ background: 'white' }}
          underlayClass="mobile-nav--underlay"
          dialogStyle={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'unset'
          }}
          dialogClass="mobile-nav--dialog">

          <img
            id="close"
            className="btn--close icon"
            src={closeIcon}
            onClick={() => {
              this.setState({ open: false });
              setTimeout(this.props.closeModal, 550);
            }}
            alt="close navigation button" />


          <div
            className="mobile-nav__hamburger"
            onClick={() => {
              setTimeout(this.props.closeModal, 550);
            }}>


            <LayoutGroup animateOnMount={true} preEnterPose={'preEnter'}>
              <motion.ul
                className="nav__list mobile-nav__list"
                animateOnMount={true}
                layout={true}
                key="ul"
                initial={{
                  opacity: 0,
                  height: '0px'
                }}
                animate={{
                  opacity: 1,
                  height: '200px',
                  delay: 400
                }}
                transition={{
                  height: { type: 'spring', stiffness: 1000, damping: 20 },
                  default: { duration: 0.9 },
                  delay: 0.2
                }}>

                {navLinks.map((link, i) => {
                  return (
                    <li key={`link-${i}`}>
                      <Link
                        className={`burger-nav mobile-nav--highlight mobile-nav--highlight--${link.color}`}
                        to={link.link}>

                        <span className="mobile-nav__hamburger-text">{link.title}</span>
                      </Link>
                    </li>);

                })}
              </motion.ul>
            </LayoutGroup>
          </div>

          <div className="mobile-nav__footer">

            <div className="mobile-nav__social">
              <a href="https://instagram.com/nikesb" target="blank">
                <img
                  src={instagramIcon}
                  className="icon"
                  alt="" />

              </a>
              <a href="https://twitter.com/nikesb" target="blank">
                <img
                  src={twitterIcon}
                  className="icon"
                  alt="" />

              </a>
              <a
                href="https://www.facebook.com/NikeSkateboarding/"
                target="blank">

                <img
                  src={facebookIcon}
                  className="icon"
                  alt="" />

              </a>
            </div>
          </div>
        </AriaModal>
      </CSSTransition>);

  }
}