{
  "AT": { 
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike verarbeitet Informationen über Ihren Besuch unter Einsatz von Cookies, um die Leistung der Website zu verbessern, das Teilen von Informationen in sozialen Netzwerken zu erleichtern und auf Ihre Interessen zugeschnittene Werbung anzubieten. Durch die weitere Nutzung unserer Seite willigen Sie in die Nutzung dieser Cookies ein. Weitere Informationen hierzu finden Sie in unserer "
      },
      {
        "block": "privacyLink",
        "copy": "Datenschutz- und Cookie-Richtlinie"
      },
      {
        "block": "copy",
        "copy": ". Sie können Ihre Einstellungen in den "
      },
      {
        "block": "settings",
        "copy": "Cookie-Einstellungen"
      },
      {
        "block": "copy",
        "copy": " anpassen."
      }
    ],
    "cookieSettings": "Deine Cookie-Einstellungen",
    "termsLink": "Verkaufsbedingungen",
    "privacyLink": "Datenschutz- und Cookie-Richtlinie",
    "functional": {
      "headline": "Funktionell",
      "copy": "Mit ihrer Hilfe funktionieren die Bereiche Warenkorb und Kasse reibungslos und sie tragen außerdem zu einer sicheren und vorschriftsmäßigen Nutzung der Seite bei."
    },
    "performance": {
      "headline": "Leistung",
      "copy": "Mit diesen Cookies können wir durch Tracken von Nutzerverhalten auf dieser Website die Funktionalität der Seite verbessern. In einigen Fällen wird durch die Cookies die Geschwindigkeit erhöht, mit der wir deine Anfrage bearbeiten können. Außerdem können deine ausgewählten Einstellungen auf unserer Seite gespeichert werden. Das Deaktivieren dieser Cookies kann zu schlecht ausgewählten Empfehlungen und einem langsamen Seitenaufbau führen."
    },
    "social": {
      "headline": "Soziale Medien und Werbung",
      "copy": [
        {
          "block": "copy",
          "copy": "Cookies in sozialen Medien bieten die Möglichkeit, dich mit deinen sozialen Netzwerken zu verbinden und Inhalte unserer Website über soziale Medien zu teilen. Werbe-Cookies (von Dritten) sammeln Informationen, um Werbeanzeigen, sowohl auf Nike Websites als auch darüber hinaus, besser auf dich und deine Interessen zuschneiden zu können. In einigen Fällen werden deine personenbezogenen Daten für diese Cookies verarbeitet. Sieh dir für weitere Informationen zur Verarbeitung von personenbezogenen Daten unsere "
        },
        {
          "block": "privacyLink",
          "copy": "Datenschutz- und Cookie-Richtlinie"
        },
        {
          "block": "copy",
          "copy": " an. Die Deaktivierung dieser Cookies könnte dazu führen, dass dir Werbeanzeigen angezeigt werden, die möglicherweise nicht relevant für dich sind bzw. dass du dich nicht richtig mit sozialen Netzwerken wie Facebook und Twitter verbinden kannst und/oder keine Inhalte über soziale Medien teilen kannst."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Du kannst deine Einstellungen jederzeit unter \"Cookie-Einstellungen\" unten auf der Seite ändern. Genaue Informationen findest du in unserer "
      },
      {
        "block": "privacyLink",
        "copy": "Datenschutz- und Cookie-Richtlinie"
      },
      {
        "block": "copy",
        "copy": " ."
      }
    ],
    "done": "Fertig",
    "bannerOk": "Ok"
  },
  "BE": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike gebruikt cookies om informatie over jouw bezoek te verwerken voor verbetering van de prestaties van de website, om delen via social media mogelijk te maken en om advertenties aan te bieden die op jouw interesses zijn afgestemd. Door verder te navigeren over onze website, stem je in met het gebruik van deze cookies. Zie voor meer informatie onze "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy- en cookiesbeleid"
      },
      {
        "block": "copy",
        "copy": ". Je kan je voorkeuren aanpassen in "
      },
      {
        "block": "settings",
        "copy": "Cookie-instellingen"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Je Cookie-Instellingen",
    "termsLink": "Gebruiksvoorwaarden",
    "privacyLink": "Privacy- en cookiesbeleid",
    "functional": {
      "headline": "Funksjon",
      "copy": "Deze cookies zijn nodig voor de basisfunctionaliteit van de website en zijn daarom standaard ingeschakeld. Tot functionele cookies behoren cookies die ervoor zorgen dat je herkend wordt tijdens het browsen op de website binnen een en dezelfde sessie of, als je dat instelt, van sessie tot sessie. Ze maken de winkelwagen en afrekenen mogelijk, en zorgen ervoor dat de website veilig is en aan de regelgeving voldoet."
    },
    "performance": {
      "headline": "Prestaties",
      "copy": "Met behulp van deze cookies kunnen we de functionaliteit van de website verbeteren door het gebruik ervan te registreren. Sommige cookies zorgen ervoor dat het verwerken van je verzoek sneller gaat, doordat ze de door jou geselecteerde websitevoorkeuren onthouden. Als je deze cookies uitschakelt, kan dat leiden tot minder gerichte aanbevelingen en een tragere website."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies bieden de mogelijkheid om u aan te sluiten op uw sociale netwerken en de inhoud van onze website te delen via social media. Reclame cookies (van derden) te verzamelen informatie beter toe te snijden reclame helpen om uw belangen, zowel binnen als buiten Nike websites. In sommige gevallen zijn deze cookies betrekking hebben op de verwerking van uw persoonlijke gegevens. Voor meer informatie over deze verwerking van persoonsgegevens, check onze "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy- en cookiesbeleid"
        },
        {
          "block": "copy",
          "copy": ". De-selectie van deze cookies kan leiden tot het zien van de reclame, dat is niet zo relevant voor u of u niet in staat om effectief te koppelen aan Facebook, Twitter of andere sociale netwerken en / of niet toe te staan om de inhoud op sociale media te delen."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Je kunt je voorkeur altijd wijzigen via \"Cookie-instellingen\" onder aan de pagina. Lees het "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy- en cookiesbeleid"
      },
      {
        "block": "copy",
        "copy": " voor meer informatie."
      }
    ],
    "done": "Klaar",
    "bannerOk": "Ok"
  },
  "BG": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy Policy"
      },
      {
        "block": "copy",
        "copy": ". You can adjust your preferences in "
      },
      {
        "block": "settings",
        "copy": "Cookie Settings"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Your Cookie Settings",
    "termsLink": "Terms of Use",
    "privacyLink": "Privacy & Cookie Policy",
    "functional": {
      "headline": "Functional",
      "copy": "These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond Nike websites. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy & Cookie Policy"
        },
        {
          "block": "copy",
          "copy": ". De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "You can always change your preference by visiting the 'Cookie Settings' at the bottom of the page. View "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy & Cookie Policy"
      },
      {
        "block": "copy",
        "copy": " for full details."
      }
    ],
    "done": "Done",
    "bannerOk": "Ok"
  },
  "HR": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy Policy"
      },
      {
        "block": "copy",
        "copy": ". You can adjust your preferences in "
      },
      {
        "block": "settings",
        "copy": "Cookie Settings"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Your Cookie Settings",
    "termsLink": "Terms of Use",
    "privacyLink": "Privacy & Cookie Policy",
    "functional": {
      "headline": "Functional",
      "copy": "These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond Nike websites. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy & Cookie Policy"
        },
        {
          "block": "copy",
          "copy": ". De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "You can always change your preference by visiting the 'Cookie Settings' at the bottom of the page. View "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy & Cookie Policy"
      },
      {
        "block": "copy",
        "copy": " for full details."
      }
    ],
    "done": "Done",
    "bannerOk": "Ok"
  },
  "CZ": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "S pomocí souborů tzv. cookies společnost Nike zpracovává údaje o Vaší návštěvě webových stránek, aby tak vylepšila jejich fungování, usnadnila sdílení na sociálních sítích a nabídla reklamu zaměřenou přímo na to, co Vás zajímá. Pokračujete-li v prohlížení těchto webových stránek, vyjadřujete tím souhlas s používáním souborů tzv. cookies. Další informace najdete v naší "
      },
      {
        "block": "privacyLink",
        "copy": "Zásady pro ochranu osobních údajů a používání souborů cookie"
      },
      {
        "block": "copy",
        "copy": ". Své preference můžete nastavit pod záložkou "
      },
      {
        "block": "settings",
        "copy": "Nastavení souborů cookie"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Tvá Nastavení Souborů Cookie",
    "termsLink": "Podmínky používání",
    "privacyLink": "Zásady pro ochranu osobních údajů a používání souborů cookie",
    "functional": {
      "headline": "Funkčnost",
      "copy": "Tyto soubory cookie jsou nutné pro základní funkce stránky, a jsou proto vždy povolené. Mezi ně patří soubory cookie, které stránce umožňují si tě zapamatovat při procházení stránky v rámci jedné relace nebo, pokud o to požádáš, mezi relacemi. Umožňují používat nákupní košík a pokladnu a také pomáhají se zabezpečením a plněním předpisů."
    },
    "performance": {
      "headline": "Výkon",
      "copy": "Tyto soubory cookie nám pomáhají vylepšovat funkce stránek sledováním využití této webové stránky. V některých případech zrychlují zpracování tvého požadavku a umožňují nám zapamatovat si tvoje vybrané předvolby na stránce. Pokud soubory cookie zakážeš, může se tím zhoršit přesnost našich doporučení a zpomalit funkčnost stránek."
    },
    "social": {
      "headline": "Sociální média a reklamy",
      "copy": [
        {
          "block": "copy",
          "copy": "Sociální média sušenky nabízejí možnost pro připojení k sociálním sítím a sdílet obsah z našich webových stránek prostřednictvím sociálních médií. Reklamní sušenky (třetích stran) shromažďovat informace, které pomohou lépe přizpůsobit reklamy svých zájmů, a to jak uvnitř i mimo internetových stránkách Nike. V některých případech se tyto sušenky zahrnovat zpracovávání vašich osobních údajů. Pro více informací o tomto zpracování osobních údajů, zkontrolujte, zda v "
        },
        {
          "block": "privacyLink",
          "copy": "Zásady pro ochranu osobních údajů a používání souborů cookie"
        },
        {
          "block": "copy",
          "copy": ". De-zvolením tyto cookies může mít za následek vidí reklamu, která není tak důležité pro vás, nebo jste nebyli schopni efektivně propojit s Facebook, Twitter nebo jiné sociální sítě a / nebo není umožňuje sdílet obsah na sociálních médií."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Nastavení můžeš kdykoli změnit v nabídce „Nastavení souborů cookie“ v dolní části stránky. Přečti si "
      },
      {
        "block": "privacyLink",
        "copy": "Zásady pro ochranu osobních údajů a používání souborů cookie"
      },
      {
        "block": "copy",
        "copy": ", kde najdeš kompletní informace."
      }
    ],
    "done": "Hotovo",
    "bannerOk": "Ok"
  },
  "DK": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike behandler information om dit besøg ved brug af cookies til at forbedre hjemmesidens ydeevne, fremme delinger på sociale medier og ved at tilbyde skræddersyet markedsføring efter dine interesser. Ved at fortsætte med at anvende vores hjemmeside accepterer du brugen af cookies. For mere information se vores "
      },
      {
        "block": "privacyLink",
        "copy": "Privatlivspolitik og cookies"
      },
      {
        "block": "copy",
        "copy": ". Du kan tilpasse dine indstillinger under "
      },
      {
        "block": "settings",
        "copy": "Indstillinger for cookies"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Dine Indstillinger for Cookies",
    "termsLink": "Betingelser for brug",
    "privacyLink": "Privatlivspolitik og cookies",
    "functional": {
      "headline": "Funktionelle",
      "copy": "Disse cookies er nødvendige for sidens grundlæggende funktionalitet og er derfor altid slået til. De omfatter cookies, der gør det muligt for dig at blive husket, mens du udforsker siden i en enkelt session eller, hvis du ønsker det, fra gang til gang. De er med til at aktivere indkøbskurven og betalingsprocessen, ligesom de hjælper i forbindelse med sikkerhedsanliggender og overholdelse af regulativer."
    },
    "performance": {
      "headline": "Brugeropleveise",
      "copy": "Disse cookies gør os i stand til at forbedre sidens funktionalitet ved at følge brugen af dette website. I nogle tilfælde forbedrer disse cookies den hastighed, hvormed vi kan behandle din anmodning, og gør det muligt for os at huske de præferencer, du har valgt. Vælger du disse cookies fra, kan det føre til dårligt tilpassede anbefalinger og langsom ydeevne på websitet."
    },
    "social": {
      "headline": "Sociale medier og reklame",
      "copy": [
        {
          "block": "copy",
          "copy": "Cookies fra sociale medier giver mulighed for, at du kan oprette forbindelse til dine sociale netværk og dele indhold fra vores hjemmeside via sociale medier. Reklamecookies (fra tredjeparter) indsamler information for bedre at kunne målrette markedsføring efter dine interesser, både på og udenfor Nikes hjemmesider. I visse tilfælde kan disse cookies involvere behandling af dine personlige data. Du kan se mere information om denne behandling af personlige data i vores "
        },
        {
          "block": "privacyLink",
          "copy": "Privatlivspolitik og cookies"
        },
        {
          "block": "copy",
          "copy": ". Hvis du fravælger disse cookies, kan det medføre, at du ser reklamer, der ikke er relevante for dig, eller at du ikke kan oprette forbindelse til Facebook, Twitter eller andre sociale medier, og/eller at du ikke kan dele indhold på sociale medier."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Du kan altid ændre dine indstillinger ved at besøge \"Indstillinger for cookies\" nederst på siden. Vis "
      },
      {
        "block": "privacyLink",
        "copy": "Privatlivspolitik og cookies"
      },
      {
        "block": "copy",
        "copy": " for detaljer."
      }
    ],
    "done": "Færdig",
    "bannerOk": "Ok"
  },
  "FI": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy Policy"
      },
      {
        "block": "copy",
        "copy": ". You can adjust your preferences in "
      },
      {
        "block": "settings",
        "copy": "Cookie Settings"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Your Cookie Settings",
    "termsLink": "Terms of Use",
    "privacyLink": "Privacy & Cookie Policy",
    "functional": {
      "headline": "Functional",
      "copy": "These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond Nike websites. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy & Cookie Policy"
        },
        {
          "block": "copy",
          "copy": ". De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "You can always change your preference by visiting the 'Cookie Settings' at the bottom of the page. View "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy & Cookie Policy"
      },
      {
        "block": "copy",
        "copy": " for full details."
      }
    ],
    "done": "Done",
    "bannerOk": "Ok"
  },
  "FR": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike recueille les informations relatives à votre visite en utilisant des cookies pour améliorer la performance du site, faciliter les partages sur les réseaux sociaux et offrir des publicités adaptées à vos centres d’intérêt. En continuant à naviguer sur notre site, vous consentez à l’utilisation de ces cookies. Pour plus d’informations, consultez notre "
      },
      {
        "block": "privacyLink",
        "copy": "Politique de confidentialité et en matière de cookies"
      },
      {
        "block": "copy",
        "copy": ". Vous pouvez configurer vos préférences dans les "
      },
      {
        "block": "settings",
        "copy": "Paramètres de cookies"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Vos Paramètres de Cookies",
    "termsLink": "Conditions générales",
    "privacyLink": "Politique de confidentialité et en matière de cookies",
    "functional": {
      "headline": "Cookies functionnels",
      "copy": "Ces cookies sont nécessaires pour assurer le fonctionnement optimal du site et sont donc activés en permanence. Ils comprennent des cookies permettant de se souvenir de votre passage sur le site au cours d'une session, ou, si vous le souhaitez, de session à session. Ils participent au fonctionnement du panier d'achat et au processus d'achat, et vous aident en cas de problème de sécurité et pour vous conformer aux réglementations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "Ces cookies nous permettent d'améliorer l'ergonomie du site grâce à l'analyse de l'utilisation du site web par les visiteurs. Dans certains cas, ces cookies améliorent la vitesse de traitement de vos requêtes en nous permettant d'enregistrer vos préférences pour le site. Désactiver ces cookies peut ralentir le site et limiter la pertinence des recommandations."
    },
    "social": {
      "headline": "Cookies liés aux réseaux sociaux et à la publicité",
      "copy": [
        {
          "block": "copy",
          "copy": "Les cookies des réseaux sociaux vous permettent de vous connecter à vos réseaux sociaux et de partager des contenus de notre site Web via les réseaux sociaux. Les cookies publicitaire (ou tiers) recueillent des informations afin de vous proposer des contenus publicitaires en adéquation avec vos intérêts, en rapport avec les sites Web Nike ou non. Dans certains cas, ces cookies ont recours à l'utilisation de vos données personnelles. Pour en savoir plus sur la façon dont vos données personnelles sont utilisées, consultez notre "
        },
        {
          "block": "privacyLink",
          "copy": "Politique de confidentialité et en matière de cookies"
        },
        {
          "block": "copy",
          "copy": ". La désactivation de ces cookies peut entraîner l'affichage de publicités qui ne sont pas aussi pertinentes pour vous et empêcher une liaison efficace avec Facebook, Twitter ou d'autres réseaux sociaux et/ou vous empêcher de partager du contenu sur les réseaux sociaux."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Vous pouvez à tout moment modifier vos préférences en vous rendant dans la section « Paramètres de cookies » en bas de page.Consultez notre "
      },
      {
        "block": "privacyLink",
        "copy": "Politique de confidentialité et en matière de cookies"
      },
      {
        "block": "copy",
        "copy": " pour obtenir davantage d'informations."
      }
    ],
    "done": "TERMINÉ",
    "bannerOk": "Ok"
  },
  "DE": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike verarbeitet Informationen über Ihren Besuch unter Einsatz von Cookies, um die Leistung der Website zu verbessern, das Teilen von Informationen in sozialen Netzwerken zu erleichtern und auf Ihre Interessen zugeschnittene Werbung anzubieten. Durch die weitere Nutzung unserer Seite willigen Sie in die Nutzung dieser Cookies ein. Weitere Informationen hierzu finden Sie in unserer "
      },
      {
        "block": "privacyLink",
        "copy": "Datenschutz- und Cookie-Richtlinie"
      },
      {
        "block": "copy",
        "copy": ". Sie können Ihre Einstellungen in den "
      },
      {
        "block": "settings",
        "copy": "Cookie-Einstellungen"
      },
      {
        "block": "copy",
        "copy": " anpassen."
      }
    ],
    "cookieSettings": "Deine Cookie-Einstellungen",
    "termsLink": "Nutzungsbedingungen",
    "privacyLink": "Datenschutz- und Cookie-Richtlinie",
    "functional": {
      "headline": "Funktionell",
      "copy": "Mit ihrer Hilfe funktionieren die Bereiche Warenkorb und Kasse reibungslos und sie tragen außerdem zu einer sicheren und vorschriftsmäßigen Nutzung der Seite bei."
    },
    "performance": {
      "headline": "Leistung",
      "copy": "Mit diesen Cookies können wir durch Tracken von Nutzerverhalten auf dieser Website die Funktionalität der Seite verbessern. In einigen Fällen wird durch die Cookies die Geschwindigkeit erhöht, mit der wir deine Anfrage bearbeiten können. Außerdem können deine ausgewählten Einstellungen auf unserer Seite gespeichert werden. Das Deaktivieren dieser Cookies kann zu schlecht ausgewählten Empfehlungen und einem langsamen Seitenaufbau führen."
    },
    "social": {
      "headline": "Soziale Medien und Werbung",
      "copy": [
        {
          "block": "copy",
          "copy": "Cookies in sozialen Medien bieten die Möglichkeit, dich mit deinen sozialen Netzwerken zu verbinden und Inhalte unserer Website über soziale Medien zu teilen. Werbe-Cookies (von Dritten) sammeln Informationen, um Werbeanzeigen, sowohl auf Nike Websites als auch darüber hinaus, besser auf dich und deine Interessen zuschneiden zu können. In einigen Fällen werden deine personenbezogenen Daten für diese Cookies verarbeitet. Sieh dir für weitere Informationen zur Verarbeitung von personenbezogenen Daten unsere "
        },
        {
          "block": "privacyLink",
          "copy": "Datenschutz- und Cookie-Richtlinie"
        },
        {
          "block": "copy",
          "copy": " an. Die Deaktivierung dieser Cookies könnte dazu führen, dass dir Werbeanzeigen angezeigt werden, die möglicherweise nicht relevant für dich sind bzw. dass du dich nicht richtig mit sozialen Netzwerken wie Facebook und Twitter verbinden kannst und/oder keine Inhalte über soziale Medien teilen kannst."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Du kannst deine Einstellungen jederzeit unter \"Cookie-Einstellungen\" unten auf der Seite ändern. Genaue Informationen findest du in unserer "
      },
      {
        "block": "privacyLink",
        "copy": "Datenschutz- und Cookie-Richtlinie"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "done": "Fertig",
    "bannerOk": "Ok"
  },
  "GR": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "H Nike επεξεργάζεται δεδομένα σχετικά με την επίσκεψή σας, χρησιμοποιώντας cookies για να βελτιώσει την απόδοση του ιστότοπου, να διευκολύνει τις κοινοποιήσεις χρησιμοποιώντας μέσα κοινωνικής δικτύωσης και για να προσφέρει διαφημιστικές πληροφορίες ανάλογα με τα ενδιαφέροντά σας. Συνεχίζοντας την περιήγησή σας στον ιστότοπό μας, συμφωνείτε με τη χρήση αυτών των cookies. Για περισσότερες πληροφορίες συμβουλευτείτε την "
      },
      {
        "block": "privacyLink",
        "copy": "Πολιτική απορρήτου και cookies"
      },
      {
        "block": "copy",
        "copy": " . Μπορείτε να προσαρμόσετε τις προτιμήσεις σας στο πεδίο "
      },
      {
        "block": "settings",
        "copy": "Ρυθμίσεις cookies"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Ρυθμίσεις cookies",
    "termsLink": "Όροι χρήσης",
    "privacyLink": "Πολιτική απορρήτου και cookies",
    "functional": {
      "headline": "Λειτουργικά",
      "copy": "Αυτά τα cookies είναι απαραίτητα για τη στοιχειώδη λειτουργία του ιστότοπου και επομένως είναι πάντα ενεργοποιημένα. Σε αυτά περιλαμβάνονται και τα cookies που αποθηκεύουν τα στοιχεία σου όσο βρίσκεσαι στις σελίδες μας ή, αν θέλεις, και σε κάθε σου επίσκεψη. Επιτρέπουν την προσθήκη προϊόντων στο καλάθι αλλά και τη διαδικασία checkout, ενώ βοηθούν στην επίλυση προβλημάτων ασφαλείας και στη συμμόρφωση με τους κανονισμούς."
    },
    "performance": {
      "headline": "Απόδοσης",
      "copy": "Χρησιμοποιούμε αυτά τα cookies για να βελτιώσουμε τη λειτουργικότητα του ιστότοπου, καταγράφοντας τον τρόπο που τον χρησιμοποιούν οι επισκέπτες. Σε ορισμένες περιπτώσεις αυτά τα cookies μάς επιτρέπουν να επεξεργαζόμαστε τα αιτήματά σου πιο γρήγορα και να θυμόμαστε τις επιλεγμένες προτιμήσεις σου. Αν καταργήσεις τη χρήση αυτών των cookies, ενδέχεται να μην λαμβάνεις πλέον εξατομικευμένες προτάσεις για προϊόντα και να παρουσιάζονται καθυστερήσεις στην απόδοση του ιστότοπου."
    },
    "social": {
      "headline": "Διαφήμισης και μέσων κοινωνικής δικτύωσης",
      "copy": [
        {
          "block": "copy",
          "copy": "Με τα cookie κοινωνικής δικτύωσης μπορείς να συνδεθείς στα κοινωνικά σου δίκτυα και να κοινοποιήσεις εκεί περιεχόμενο από τον ιστότοπό μας. Τα διαφημιστικά cookie (τρίτων μερών) συλλέγουν πληροφορίες που συμβάλλουν στην καλύτερη εξατομίκευση των διαφημίσεων, ώστε να ανταποκρίνονται στα ενδιαφέροντά σου, εντός κι εκτός των ιστότοπων της Nike. Σε ορισμένες περιπτώσεις, αυτά τα cookie συνεπάγονται την επεξεργασία των προσωπικών σου δεδομένων. Για περισσότερες πληροφορίες σχετικά με την επεξεργασία προσωπικών δεδομένων, δες την "
        },
        {
          "block": "privacyLink",
          "copy": "Πολιτική απορρήτου και cookies"
        },
        {
          "block": "copy",
          "copy": ". Αν απενεργοποιήσεις αυτά τα cookie, μπορεί να βλέπεις διαφημίσεις που δεν έχουν σχέση με τα ενδιαφέροντά σου ή να μην μπορείς να συνδεθείς κανονικά με το Facebook, το Twitter ή άλλα κοινωνικά δίκτυα και να κοινοποιήσεις εκεί περιεχόμενο."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Μπορείς να αλλάξεις οποιαδήποτε στιγμή τις προτιμήσεις σου, επιλέγοντας το στοιχείο \"Ρυθμίσεις cookies\" από το κάτω μέρος της σελίδας. Για πλήρεις λεπτομέρειες, δες την "
      },
      {
        "block": "privacyLink",
        "copy": "Πολιτική Απορρήτου και Cookies"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "done": "ΤΕΛΟΣ",
    "bannerOk": "Ok"
  },
  "HU": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "A Nike az Ön webhely látogatásával kapcsolatos információk feldolgozásához sütiket (cookie-kat) használ annak érdekében, hogy javítsa a weboldal teljesítményét, elősegítse a közösségi médiában történő megosztást és az Ön érdeklődésére szabott reklámokat kínáljon. Webhelyünk böngészésével Ön elfogadja az ilyen sütik használatát. További információkat "
      },
      {
        "block": "privacyLink",
        "copy": "Adatvédelmi és cookie-szabályzat találhat"
      },
      {
        "block": "copy",
        "copy": ". A süti beállításokat a "
      },
      {
        "block": "settings",
        "copy": "Cookie-beállítások"
      },
      {
        "block": "copy",
        "copy": " menüpont alatt módosíthatja."
      }
    ],
    "cookieSettings": "Cookie-beállítások",
    "termsLink": "Használati feltételek",
    "privacyLink": "Adatvédelmi szabályzat",
    "functional": {
      "headline": "Funzionalità",
      "copy": "Ezekre a cookie-kra a webhely alapfunkcióinak biztosításához van szükség, ezért mindig engedélyezve vannak. Szerepelnek közöttük olyan cookie-k, amelyek lehetővé teszik, hogy a rendszer megjegyezzen téged, amikor egy munkameneten belül a webhelyet böngészed, illetve kérésedre olyanok is, amelyek a munkamenetek között is megjegyeznek. Segítenek a bevásárlókosár működtetésében és a fizetési folyamat lebonyolításában, valamint a biztonsági funkciók működését és a szabályok betartását is lehetővé teszik."
    },
    "performance": {
      "headline": "Teljesítmény",
      "copy": "Ezek a cookie-k lehetővé teszik számunkra, hogy tökéletesítsük az oldal működését a jelen weboldal használatának nyomon követésével. Bizonyos esetekben ezek a cookie-k növelik a kérések feldolgozásának gyorsaságát, emellett lehetővé teszik, hogy megjegyezzük az általad előnyben részesített oldalbeállításokat. Ha nem engedélyezed ezeket a cookie-kat, akkor előfordulhat, hogy a javaslatok nem igazán a te igényeidre lesznek szabva, emellett az oldal teljesítménye is lassulhat."
    },
    "social": {
      "headline": "Közösségi média és hirdetés",
      "copy": [
        {
          "block": "copy",
          "copy": "A közösségi hálózati cookie-k révén csatlakozhatsz közösségi hálózataidhoz, és webhelyünkről tartalmakat oszthatsz meg a közösségi hálózatokon keresztül. A (harmadik felektől származó) hirdetési cookie-k a Nike webhelyein és azon túl egyaránt adatokat gyűjtenek, hogy a hirdetéseket jobban az érdeklődésedre alakítsák. Egyes esetekben ezek a cookie-k személyes adatok feldolgozásával járnak. A személyes adatok feldolgozásával kapcsolatos további információért tekintsd meg a következőt: "
        },
        {
          "block": "privacyLink",
          "copy": "Adatvédelmi és cookie-szabályzat"
        },
        {
          "block": "copy",
          "copy": ". Ha törlöd a cookie-k kijelölését, akkor előfordulhat, hogy olyan hirdetéseket látsz, amelyek nem relevánsak rád nézve, illetve hogy nem tudsz a Facebookhoz, a Twitterhez vagy más közösségi hálózatokhoz eredményesen kapcsolódni, és/vagy nem tudsz tartalmakat megosztani a közösségi hálózatokon."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Ezt a beállítást bármikor megváltoztathatod a \"Cookie-beállítások\" menüre kattintva az oldal alján. Részletek: "
      },
      {
        "block": "privacyLink",
        "copy": "Adatvédelmi és cookie-szabályzat"
      }
    ],
    "done": "KÉSZ",
    "bannerOk": "Ok"
  },
  "IE": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy Policy"
      },
      {
        "block": "copy",
        "copy": ". You can adjust your preferences in "
      },
      {
        "block": "settings",
        "copy": "Cookie Settings"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Your Cookie Settings",
    "termsLink": "Terms of Use",
    "privacyLink": "Privacy & Cookie Policy",
    "functional": {
      "headline": "Functional",
      "copy": "These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond Nike websites. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy & Cookie Policy"
        },
        {
          "block": "copy",
          "copy": ". De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "You can always change your preference by visiting the 'Cookie Settings' at the bottom of the page. View "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy & Cookie Policy"
      },
      {
        "block": "copy",
        "copy": " for full details."
      }
    ],
    "done": "Done",
    "bannerOk": "Ok"
  },
  "IT": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike tratta le informazioni relative al tuo accesso attraverso l'utilizzo di cookies che aumentano la funzionalità del sito, facilitano la condivisione tramite i social media e sono in grado di offrire annunci pubblicitari focalizzati sui tuoi interessi. Proseguendo la navigazione acconsenti pertanto all'utilizzo di tali cookies. Per ulteriori informazioni a riguardo, puoi consultare la nostra "
      },
      {
        "block": "privacyLink",
        "copy": "Politica sulla privacy e sui cookie"
      },
      {
        "block": "copy",
        "copy": ". Inoltre, puoi modificare le tue preferenze attraverso l'apposita sezione "
      },
      {
        "block": "settings",
        "copy": "Impostazioni cookie"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Impostazioni dei Cookie",
    "termsLink": "Condizioni d'uso",
    "privacyLink": "Politica sulla privacy e sui cookie",
    "functional": {
      "headline": "Funzionalità",
      "copy": "Questi cookie sono richiesti per le funzionalità di base del sito e sono, pertanto, sempre abilitati. Si tratta di cookie che consentono di riconoscere l'utente che utilizza il sito durante un'unica sessione o, su richiesta, anche nelle sessioni successive. Questo tipo di cookie consente di riempire il carrello, eseguire facilmente le operazioni di pagamento, risolvere problemi legati alla sicurezza e garantire la conformità alle normative vigenti."
    },
    "performance": {
      "headline": "Prestazioni",
      "copy": "Questi cookie ci consentono di migliorare le funzionalità del sito tenendo traccia dell'utilizzo del sito Web stesso. In alcuni casi, questi cookie velocizzano l'elaborazione delle richieste dell'utente, in quanto ci aiutano a ricordare le preferenze che hai selezionato per il sito. Se disabiliti questi cookie potresti ricevere consigli poco personalizzati e riscontrare lentezza nell'uso del sito."
    },
    "social": {
      "headline": "Social media e pubblicita",
      "copy": [
        {
          "block": "copy",
          "copy": "I cookie dei social media offrono la possibilità di connetterti ai tuoi social newtork e condividere i contenuti del nostro sito Web attraverso i social media. I cookie pubblicitari (di terzi) raccolgono informazioni per offrirti pubblicità personalizzate in base ai tuoi interessi, all'interno e al di fuori dei siti Nike. In alcuni casi, questi cookie prevedono l'elaborazione dei tuoi dati personali. Per maggiori informazioni sull'elaborazione dei dati personali, consulta la nostra "
        },
        {
          "block": "privacyLink",
          "copy": "Politica sulla privacy e sui cookie"
        },
        {
          "block": "copy",
          "copy": ". Se deselezioni questi cookie, potresti vedere pubblicità non pertinenti, oppure potresti non riuscire a connetterti con Facebook, Twitter o altri social network e/o potrebbe non esserti consentito condividere contenuti sui social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Puoi sempre modificare le tue preferenze accedendo alla sezione \"Impostazioni cookie\" in fondo alla pagina. Per i dettagli completi, consulta la "
      },
      {
        "block": "privacyLink",
        "copy": "Politica sulla privacy e sui cookie"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "done": "Fine",
    "bannerOk": "Ok"
  },
  "LU": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike recueille les informations relatives à votre visite en utilisant des cookies pour améliorer la performance du site, faciliter les partages sur les réseaux sociaux et offrir des publicités adaptées à vos centres d’intérêt. En continuant à naviguer sur notre site, vous consentez à l’utilisation de ces cookies. Pour plus d’informations, consultez notre "
      },
      {
        "block": "privacyLink",
        "copy": "Politique de confidentialité et en matière de cookies"
      },
      {
        "block": "copy",
        "copy": ". Vous pouvez configurer vos préférences dans les "
      },
      {
        "block": "settings",
        "copy": "Paramètres de cookies"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Vos Paramètres de Cookies",
    "termsLink": "Politique de confidentialité et en matière de cookies",
    "privacyLink": "Conditions générales",
    "functional": {
      "headline": "Cookies functionnels",
      "copy": "Ces cookies sont nécessaires pour assurer le fonctionnement optimal du site et sont donc activés en permanence. Ils comprennent des cookies permettant de se souvenir de votre passage sur le site au cours d'une session, ou, si vous le souhaitez, de session à session. Ils participent au fonctionnement du panier d'achat et au processus d'achat, et vous aident en cas de problème de sécurité et pour vous conformer aux réglementations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "Ces cookies nous permettent d'améliorer l'ergonomie du site grâce à l'analyse de l'utilisation du site web par les visiteurs. Dans certains cas, ces cookies améliorent la vitesse de traitement de vos requêtes en nous permettant d'enregistrer vos préférences pour le site. Désactiver ces cookies peut ralentir le site et limiter la pertinence des recommandations."
    },
    "social": {
      "headline": "Cookies liés aux réseaux sociaux et à la publicité",
      "copy": [
        {
          "block": "copy",
          "copy": "Les cookies des réseaux sociaux vous permettent de vous connecter à vos réseaux sociaux et de partager des contenus de notre site Web via les réseaux sociaux. Les cookies publicitaire (ou tiers) recueillent des informations afin de vous proposer des contenus publicitaires en adéquation avec vos intérêts, en rapport avec les sites Web Nike ou non. Dans certains cas, ces cookies ont recours à l'utilisation de vos données personnelles. Pour en savoir plus sur la façon dont vos données personnelles sont utilisées, consultez notre "
        },
        {
          "block": "privacyLink",
          "copy": "Politique de confidentialité et en matière de cookies"
        },
        {
          "block": "copy",
          "copy": ". La désactivation de ces cookies peut entraîner l'affichage de publicités qui ne sont pas aussi pertinentes pour vous et empêcher une liaison efficace avec Facebook, Twitter ou d'autres réseaux sociaux et/ou vous empêcher de partager du contenu sur les réseaux sociaux."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Vous pouvez à tout moment modifier vos préférences en vous rendant dans la section « Paramètres de cookies » en bas de page.Consultez notre "
      },
      {
        "block": "privacyLink",
        "copy": "Politique de confidentialité et en matière de cookies"
      },
      {
        "block": "copy",
        "copy": " pour obtenir davantage d'informations."
      }
    ],
    "done": "TERMINÉ",
    "bannerOk": "Ok"
  },
  "NL": {
    "modalLanguage": [
      {
        "block": "copy",
        "copy": "Nike vraagt je om cookies te accepteren voor betere prestaties en voor sociale-media- en advertentiedoeleinden. Er worden sociale-media- en advertentiecookies van derden gebruikt om je sociale-mediafunctionaliteit en persoonlijke advertenties te bieden. Klik op de knop MEER INFO of ga naar Cookie-instellingen onder aan de pagina voor meer informatie of om je voorkeuren te wijzigen. Lees ons "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy- en cookiesbeleid"
      },
      {
        "block": "copy",
        "copy": " voor meer informatie over deze cookies en de verwerking van je persoonsgegevens. Accepteer je deze cookies en de bijbehorende verwerking van je persoonsgegevens?"
      }
    ],
    "cookieSettings": "Je Cookie-instellingen",
    "termsLink": "Gebruiksvoorwaarden",
    "privacyLink": "Privacy- en cookiesbeleid",
    "functional": {
      "headline": "Funksjon",
      "copy": "Deze cookies zijn nodig voor de basisfunctionaliteit van de website en zijn daarom standaard ingeschakeld. Tot functionele cookies behoren cookies die ervoor zorgen dat je herkend wordt tijdens het browsen op de website binnen een en dezelfde sessie of, als je dat instelt, van sessie tot sessie. Ze maken de winkelwagen en afrekenen mogelijk, en zorgen ervoor dat de website veilig is en aan de regelgeving voldoet."
    },
    "performance": {
      "headline": "Prestaties",
      "copy": "Met behulp van deze cookies kunnen we de functionaliteit van de website verbeteren door het gebruik ervan te registreren. Sommige cookies zorgen ervoor dat het verwerken van je verzoek sneller gaat, doordat ze de door jou geselecteerde websitevoorkeuren onthouden. Als je deze cookies uitschakelt, kan dat leiden tot minder gerichte aanbevelingen en een tragere website."
    },
    "social": {
      "headline": "Sociale media en advertenties",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies bieden de mogelijkheid om u aan te sluiten op uw sociale netwerken en de inhoud van onze website te delen via social media. Reclame cookies (van derden) te verzamelen informatie beter toe te snijden reclame helpen om uw belangen, zowel binnen als buiten Nike websites. In sommige gevallen zijn deze cookies betrekking hebben op de verwerking van uw persoonlijke gegevens. Voor meer informatie over deze verwerking van persoonsgegevens, check onze "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy- en cookiesbeleid"
        },
        {
          "block": "copy",
          "copy": ". De-selectie van deze cookies kan leiden tot het zien van de reclame, dat is niet zo relevant voor u of u niet in staat om effectief te koppelen aan Facebook, Twitter of andere sociale netwerken en / of niet toe te staan om de inhoud op sociale media te delen."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Je kunt je voorkeur altijd wijzigen via \"Cookie-instellingen\" onder aan de pagina. Lees het "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy- en cookiesbeleid"
      },
      {
        "block": "copy",
        "copy": " voor meer informatie."
      }
    ],
    "done": "Klaar",
    "moreInfo": "Meer Info",
    "bannerOk": "Ja, Ik Accepteer Ze"
  },
  "NO": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike behandler informasjon om ditt besøk ved bruk av informasjonskapsler (cookies) til å forbedre hjemmesidens yteevne, fremme delinger på sosiale medier og ved å tilby markedsføring skreddersydd etter dine interesser. Ved å fortsette med å bruke vår hjemmeside aksepterer du bruken av cookies. For mer informasjon, se våre "
      },
      {
        "block": "privacyLink",
        "copy": "Personverns- og informasjonskapselvilkår"
      },
      {
        "block": "copy",
        "copy": ". Du kan tilpasse dine innstillinger under "
      },
      {
        "block": "settings",
        "copy": "Innstillinger for informasjonskapsler"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Dine Innstillinger for Informasjonskapsler",
    "termsLink": "Bruksvilkår",
    "privacyLink": "Personvern- og informasjonskapselvilkår",
    "functional": {
      "headline": "Funksjon",
      "copy": "Disse informasjonskapslene er nødvendig for nettsidens grunnleggende funksjoner og er derfor alltid aktiverte. Dette inkluderer informasjonskapsler som gjør at du blir husket når du utforsker nettsiden innenfor én enkelt økt eller, hvis du ber om det, fra økt til økt. De bidrar til å gjøre handlevogn- og betalingsprosessen mulig, bistår i sikkerhetsspørsmål og sørger for at forskriftene følges."
    },
    "performance": {
      "headline": "Ytelse",
      "copy": "Disse informasjonskapslene bidrar til at vi kan forbedre nettsidens funksjoner ved å spore bruken av denne siden. I noen tilfeller bidrar disse informasjonskapslene til at vi kan behandle forespørselen din raskere, ved at de husker innstillingene du har valgt for nettsiden. Hvis du deaktiverer disse informasjonskapslene, kan det resultere i dårlig tilpassede anbefalinger og en treg nettside."
    },
    "social": {
      "headline": "Sosiale medier og reklame",
      "copy": [
        {
          "block": "copy",
          "copy": "Informasjonskapsler for sosiale medier gir deg muligheten til å koble deg til det sosiale nettverket ditt, og dele innhold fra nettstedet vårt via sosiale medier. Informasjonskapsler for annonser (fra tredjeparter) samler informasjon som bidrar til å kunne tilpasse annonseringen på en bedre måte i henhold til dine interesser, både innenfor og utenfor Nikes nettsider. I noen tilfeller kan disse informasjonskapslene innebære behandling av personopplysningene dine. Hvis du vil ha mer informasjon om hvordan vi behandler personopplysninger, kan du se våre "
        },
        {
          "block": "privacyLink",
          "copy": "Personverns- og informasjonskapselvilkår"
        },
        {
          "block": "copy",
          "copy": ". Hvis du deaktiverer disse informasjonskapslene, kan det resultere i at du ser annonser som ikke er like relevante for deg, eller at du ikke er i stand til å koble til like effektivt med Facebook, Twitter eller andre sosiale nettverk, og/eller at du ikke kan dele innhold på sosiale medier."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Du kan alltid endre innstillingene dine ved å gå til "
      },
      {
        "block": "privacyLink",
        "copy": "innstillingene for informasjonskapsler"
      },
      {
        "block": "copy",
        "copy": " nederst på siden."
      }
    ],
    "done": "Ferdig",
    "bannerOk": "Ok"
  },
  "PL": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike przetwarza informacje dotyczące Państwa wizyty stosując cookies (tzw. ciasteczka) aby poprawić efektywność strony, ułatwić dzielenie się w mediach społecznościowych i zaoferować reklamy dostosowane do Państwa zainteresowań. Kontynuując przeglądanie naszej strony zgadzacie się Państwo na korzystanie przez nas z tych cookies. W celu uzyskania szczegółowych informacji zachęcamy do zapoznania się z naszą "
      },
      {
        "block": "privacyLink",
        "copy": "Polityka ochrony prywatności i zasady dotyczące plików cookie"
      },
      {
        "block": "copy",
        "copy": ". Możecie Państwo dostosować swoje preferencje w "
      },
      {
        "block": "settings",
        "copy": "Ustawienia plików cookie"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Ustawienia Plików Cookie",
    "termsLink": "Warunki korzystania",
    "privacyLink": "Polityka ochrony prywatności i zasady dotyczące plików cookie",
    "functional": {
      "headline": "Funcionamento",
      "copy": "Są zawsze włączone, ponieważ umożliwiają podstawowe działanie strony. Są to między innymi pliki cookie zapamiętujące użytkownika na czas pojedynczej sesji lub, zależnie od wybranych opcji, na kolejne sesje. Ich zadaniem jest umożliwienie działania koszyka i procesu realizacji zamówienia, a także pomoc w rozwiązywaniu problemów z zabezpieczeniami i zachowaniu zgodności z przepisami."
    },
    "performance": {
      "headline": "Pliki cookie związane z wydajnością",
      "copy": "Te pliki cookie pomagają nam udoskonalać witrynę, dostarczając informacji o korzystaniu z niej przez użytkowników. W niektórych przypadkach pliki te umożliwiają szybsze przetworzenie żądania i pozwalają zapamiętać wybrane preferencje dotyczące witryny. Wyłączenie tych plików może spowodować wyświetlanie źle dostosowanych rekomendacji i wolne działanie strony."
    },
    "social": {
      "headline": "Pliki cookie związane z serwisami społecznościowymi i treściami reklamowymi",
      "copy": [
        {
          "block": "copy",
          "copy": "Pliki cookies w przypadku platform społecznościowych dają możliwość powiązania Cię z Twoimi kontami w mediach społecznościowych. Dzięki temu możesz w nich udostępniać treści pochodzące z naszej strony. Pliki cookies z serwerów reklamowych (pochodzące od podmiotów zewnętrznych) zbierają informacje w celu zapewnienia spersonalizowanych treści reklamowych na stronach Nike i w innych witrynach. W niektórych przypadkach pliki cookies są wykorzystywane do przetwarzania danych osobowych. Więcej informacji na temat przetwarzania danych osobowych opisuje nasza "
        },
        {
          "block": "privacyLink",
          "copy": "Polityka ochrony prywatności i zasady dotyczące plików cookie"
        },
        {
          "block": "copy",
          "copy": ". Odrzucenie tych plików cookies może skutkować wyświetlaniem niespersonalizowanych treści reklamowych oraz brakiem możliwości połączenia konta z Twoim kontem na Facebooku, Twitterze lub w innych mediach społecznościowych, a także może uniemożliwić Ci udostępnianie treści na swoich kontach."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Ustawienia można w dowolnej chwili zmienić, przechodząc do „Ustawień plików cookie” na dole strony. Aby uzyskać szczegółowe informacje, zapoznaj się z "
      },
      {
        "block": "privacyLink",
        "copy": "Polityka ochrony prywatności i zasady dotyczące plików cookie"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "done": "Gotowe",
    "bannerOk": "Ok"
  },
  "PT": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "A Nike trata informação sobre a sua visita utilizando cookies que melhoram o desempenho do sítio da web, facilitam a partilha através das redes sociais e oferecem publicidade ajustada aos seus interesses. Ao continuar a navegar no nosso sítio, aceita o uso destes cookies. Para obter mais informação, consulte a nossa "
      },
      {
        "block": "privacyLink",
        "copy": "Política de privacidade e de cookies"
      },
      {
        "block": "copy",
        "copy": ". Poderá configurar as suas preferências em "
      },
      {
        "block": "settings",
        "copy": "Definições de cookies"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "As Tuas Definições de Cookies",
    "termsLink": "Termos de Utilização",
    "privacyLink": "Política de privacidade e de cookies",
    "functional": {
      "headline": "Functional",
      "copy": "Estes cookies são necessários para o funcionamento básico do site e, por isso, estão sempre ativados. Incluem cookies que permitem que o site se lembre de ti enquanto o exploras na mesma sessão ou, se o solicitares, de uma sessão para a seguinte. Estes cookies ajudam a fazer com que os processos do carrinho de compras e de finalização das mesmas sejam possíveis, ajudando igualmente com questões de segurança e de conformidade com as regulações."
    },
    "performance": {
      "headline": "Desempenho",
      "copy": "Estes cookies permitem-nos melhorar o funcionamento do site através do rastreamento da sua utilização. Em alguns casos, aumentam a velocidade a que conseguimos processar o teu pedido, permitindo que nos recordemos das tuas preferências para o site. A não seleção destes cookies pode resultar em recomendações pouco adaptadas a ti e tornar o site lento."
    },
    "social": {
      "headline": "Redes sociais e publicidade",
      "copy": [
        {
          "block": "copy",
          "copy": "Os cookies de redes sociais oferecem a possibilidade de te ligar às tuas redes sociais e de partilhar conteúdo do nosso website através das mesmas. Os cookies de publicidade (de terceiros) recolhem informações para ajudar a adaptar melhor a publicidade aos teus interesses, tanto dentro como fora dos sites da Nike. Em alguns casos, estes cookies implicam o processamento dos teus dados pessoais. Para mais informações sobre este tratamento de dados pessoais, consulta a nossa "
        },
        {
          "block": "privacyLink",
          "copy": "Política de privacidade e de cookies"
        },
        {
          "block": "copy",
          "copy": ". Anular a seleção destes cookies pode resultar em veres publicidade que não é importante para ti ou em não conseguires uma ligação funcional ao Facebook, Twitter ou outra rede social e/ou em não poderes partilhar conteúdo em redes sociais."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Podes sempre alterar a tua preferência, visitando \"Definições de cookies\" na parte inferior da página. Consulta "
      },
      {
        "block": "privacyLink",
        "copy": "Política de privacidade e de cookies"
      },
      {
        "block": "copy",
        "copy": " para obteres mais detalhes."
      }
    ],
    "done": "CONCLUDÍO",
    "bannerOk": "Ok"
  },
  "RO": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy Policy"
      },
      {
        "block": "copy",
        "copy": ". You can adjust your preferences in "
      },
      {
        "block": "settings",
        "copy": "Cookie Settings"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Your Cookie Settings",
    "termsLink": "Terms of Use",
    "privacyLink": "Privacy & Cookie Policy",
    "functional": {
      "headline": "Functional",
      "copy": "These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond Nike websites. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy & Cookie Policy"
        },
        {
          "block": "copy",
          "copy": ". De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "You can always change your preference by visiting the 'Cookie Settings' at the bottom of the page. View "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy & Cookie Policy"
      },
      {
        "block": "copy",
        "copy": " for full details."
      }
    ],
    "done": "Done",
    "bannerOk": "Ok"
  },
  "SK": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy Policy"
      },
      {
        "block": "copy",
        "copy": ". You can adjust your preferences in "
      },
      {
        "block": "settings",
        "copy": "Cookie Settings"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Your Cookie Settings",
    "termsLink": "Terms of Use",
    "privacyLink": "Privacy & Cookie Policy",
    "functional": {
      "headline": "Functional",
      "copy": "These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond Nike websites. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy & Cookie Policy"
        },
        {
          "block": "copy",
          "copy": ". De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "You can always change your preference by visiting the 'Cookie Settings' at the bottom of the page. View "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy & Cookie Policy"
      },
      {
        "block": "copy",
        "copy": " for full details."
      }
    ],
    "done": "Done",
    "bannerOk": "Ok"
  },
  "SI": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy Policy"
      },
      {
        "block": "copy",
        "copy": ". You can adjust your preferences in "
      },
      {
        "block": "settings",
        "copy": "Cookie Settings"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Your Cookie Settings",
    "termsLink": "Terms of Use",
    "privacyLink": "Privacy & Cookie Policy",
    "functional": {
      "headline": "Functional",
      "copy": "These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond Nike websites. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy & Cookie Policy"
        },
        {
          "block": "copy",
          "copy": ". De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "You can always change your preference by visiting the 'Cookie Settings' at the bottom of the page. View "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy & Cookie Policy"
      },
      {
        "block": "copy",
        "copy": " for full details."
      }
    ],
    "done": "Done",
    "bannerOk": "Ok"
  },
  "CH": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike verarbeitet Informationen über Ihren Besuch unter Einsatz von Cookies, um die Leistung der Website zu verbessern, das Teilen von Informationen in sozialen Netzwerken zu erleichtern und auf Ihre Interessen zugeschnittene Werbung anzubieten. Durch die weitere Nutzung unserer Seite willigen Sie in die Nutzung dieser Cookies ein. Weitere Informationen hierzu finden Sie in unserer "
      },
      {
        "block": "privacyLink",
        "copy": "Datenschutz- und Cookie-Richtlinie"
      },
      {
        "block": "copy",
        "copy": ". Sie können Ihre Einstellungen in den "
      },
      {
        "block": "settings",
        "copy": "Cookie-Einstellungen"
      },
      {
        "block": "copy",
        "copy": " anpassen."
      }
    ],
    "cookieSettings": "Cookie-Einstellungen",
    "termsLink": "Nutzungsbedingungen",
    "privacyLink": "Datenschutz- und Cookie-Richtlinie",
    "functional": {
      "headline": "Funktionelle",
      "copy": "Mit ihrer Hilfe funktionieren die Bereiche Warenkorb und Kasse reibungslos und sie tragen außerdem zu einer sicheren und vorschriftsmäßigen Nutzung der Seite be"
    },
    "performance": {
      "headline": "Leistung",
      "copy": "Mit diesen Cookies können wir durch Tracken von Nutzerverhalten auf dieser Website die Funktionalität der Seite verbessern. In einigen Fällen wird durch die Cookies die Geschwindigkeit erhöht, mit der wir deine Anfrage bearbeiten können. Außerdem können deine ausgewählten Einstellungen auf unserer Seite gespeichert werden. Das Deaktivieren dieser Cookies kann zu schlecht ausgewählten Empfehlungen und einem langsamen Seitenaufbau führen."
    },
    "social": {
      "headline": "Soziale Medien und Werbung",
      "copy": [
        {
          "block": "copy",
          "copy": "Cookies in sozialen Medien bieten die Möglichkeit, dich mit deinen sozialen Netzwerken zu verbinden und Inhalte unserer Website über soziale Medien zu teilen. Werbe-Cookies (von Dritten) sammeln Informationen, um Werbeanzeigen, sowohl auf Nike Websites als auch darüber hinaus, besser auf dich und deine Interessen zuschneiden zu können. In einigen Fällen werden deine personenbezogenen Daten für diese Cookies verarbeitet. Sieh dir für weitere Informationen zur Verarbeitung von personenbezogenen Daten unsere "
        },
        {
          "block": "privacyLink",
          "copy": "Datenschutz- und Cookie-Richtlinie"
        },
        {
          "block": "copy",
          "copy": " an. Die Deaktivierung dieser Cookies könnte dazu führen, dass dir Werbeanzeigen angezeigt werden, die möglicherweise nicht relevant für dich sind bzw. dass du dich nicht richtig mit sozialen Netzwerken wie Facebook und Twitter verbinden kannst und/oder keine Inhalte über soziale Medien teilen kannst."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Du kannst deine Einstellungen jederzeit unter \"Cookie-Einstellungen\" unten auf der Seite ändern. Genaue Informationen findest du in unserer "
      },
      {
        "block": "privacyLink",
        "copy": "Datenschutz- und Cookie-Richtlinie"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "done": "Fertig",
    "bannerOk": "Ok"
  },
  "ES": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike procesa información sobre tu visita utilizando cookies que mejoran el rendimiento del sitio web, facilitan las compartición a través de redes sociales y ofrecen publicidad ajustada a tus intereses. Al continuar navegando por nuestra web, aceptas el uso de cookies. Para obtener más información, consulta nuestra "
      },
      {
        "block": "privacyLink",
        "copy": "Política de privacidad y cookies"
      },
      {
        "block": "copy",
        "copy": ". Puedes configurar tus preferencias en "
      },
      {
        "block": "settings",
        "copy": "Configuración de cookies"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Tu Configuración de Cookies",
    "termsLink": "Condiciones de uso",
    "privacyLink": "Política de privacidad y cookies",
    "functional": {
      "headline": "Cookies funcionales",
      "copy": "Estas cookies son necesarias para el funcionamiento básico del sitio web y, por lo tanto, están siempre activas. Incluyen cookies que permiten recordar las acciones que emprendes al navegar por el sitio web en una misma sesión o, si lo solicitas, de sesión a sesión. Ayudan a que el proceso del carro de la compra y de pasar por caja sea posible, además de proporcionar asistencia en cuestiones de seguridad y de conformidad con las normativas."
    },
    "performance": {
      "headline": "Rendimiento",
      "copy": "Estas cookies nos permiten mejorar la funcionalidad del sitio web realizando un seguimiento del uso de este sitio web. En algunos casos, estas cookies mejoran la velocidad con la que podemos procesar tus solicitudes, ya que nos permiten recordar las preferencias del sitio web que seleccionas. Si no se activan estas cookies, las recomendaciones podrían no ser tan personalizadas y el rendimiento del sitio web podría ralentizarse."
    },
    "social": {
      "headline": "Redes sociales y publicidad",
      "copy": [
        {
          "block": "copy",
          "copy": "Galletas de medios sociales ofrecen la posibilidad de conectarse a sus redes sociales y compartir el contenido de nuestra página web a través de los medios sociales. cookies de publicidad (de terceros) recopilan información para ayudar a mejorar la publicidad a medida de sus intereses, tanto dentro como fuera de los sitios web de Nike. En algunos casos, estas cookies implican el tratamiento de sus datos personales. Para obtener más información sobre este tratamiento de datos personales, visite nuestro "
        },
        {
          "block": "privacyLink",
          "copy": "Política de privacidad y cookies"
        },
        {
          "block": "copy",
          "copy": ". De-seleccionar estas cookies puede resultar en ver la publicidad que no es tan importante para usted, o no ser capaz de conectarse eficazmente con Facebook, Twitter u otras redes sociales y / o no lo que le permite compartir contenidos en las redes sociales."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Siempre puedes cambiar la preferencia visitando la sección de \"Configuración de cookies\" al final de la página. Consulta la "
      },
      {
        "block": "privacyLink",
        "copy": "Política de privacidad y cookies"
      },
      {
        "block": "copy",
        "copy": " para obtener más información."
      }
    ],
    "done": "Hecho",
    "bannerOk": "Ok"
  },
  "SE": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike använder cookies (”kakor”) för att behandla information om ditt besök i syfte att förbättra prestanda, underlätta funktioner i social media samt erbjuda anpassade annonser. Genom att fortsätta använda vår hemsida godkänner du användningen av cookies. För mer information, läs vår "
      },
      {
        "block": "privacyLink",
        "copy": "Integritets- och cookiepolicy"
      },
      {
        "block": "copy",
        "copy": ". Du kan ändra dina val genom att gå till "
      },
      {
        "block": "settings",
        "copy": "Cookieinställningar"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Dina Cookieinställningar",
    "termsLink": "Användarvillkor",
    "privacyLink": "Integritets- och cookiepolicy",
    "functional": {
      "headline": "Funktionellt",
      "copy": "Dessa cookies krävs för webbplatsens grundläggande funktionalitet och är därför alltid aktiverade. De inkluderar cookies som kommer ihåg var på webbplatsen du varit under en session och kan, om du begär det, även komma ihåg det från session till session. De hjälper dig att använda varukorgen och gå till kassan samt assisterar vid säkerhetsproblem och ser till att allt överensstämmer med våra regler."
    },
    "performance": {
      "headline": "Prestanda",
      "copy": "Dessa cookies tillåter oss att förbättra webbplatsens funktionalitet genom att spåra användningen. I vissa fall kan dessa cookies förbättra processhastigheten samt hjälpa oss att komma ihåg vilka platsinställningar du har valt. Om du väljer bort dessa cookies kan det resultera i dåligt anpassade rekommendationer och att webbplatsens prestanda försämras."
    },
    "social": {
      "headline": "Sociala medier och marknadsföring",
      "copy": [
        {
          "block": "copy",
          "copy": "Cookies för sociala medier gör det möjligt att ansluta till dina sociala nätverk och dela innehåll från vår webbplats via sociala medier. Cookies för reklam (från tredje parter) samlar in information i syfte att bättre anpassa annonserna utifrån dina intressen, både på och utanför Nikes webbplats. I vissa fall kan dessa cookies behandla dina personuppgifter. Mer information om behandlingen av personuppgifter finns på "
        },
        {
          "block": "privacyLink",
          "copy": "Integritets- och cookiepolicy"
        },
        {
          "block": "copy",
          "copy": ". Om du väljer att inte godkänna dessa cookies kan det hända att du ser annonser som inte är relevanta för dig, eller att du inte kan länka ditt konto till Facebook, Twitter eller något annat socialt nätverk och/eller inte kan dela innehåll via sociala medier."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "Du kan alltid ändra dina önskemål genom att besöka \"Cookieinställningar\" längst ner på sidan. Se "
      },
      {
        "block": "privacyLink",
        "copy": "Integritets- och cookiepolicy"
      },
      {
        "block": "copy",
        "copy": " för fullständig information."
      }
    ],
    "done": "Klar",
    "bannerOk": "Ok"
  },
  "GB": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy Policy"
      },
      {
        "block": "copy",
        "copy": ". You can adjust your preferences in "
      },
      {
        "block": "settings",
        "copy": "Cookie Settings"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Your Cookie Settings",
    "termsLink": "Terms of Use",
    "privacyLink": "Privacy & Cookie Policy",
    "functional": {
      "headline": "Functional",
      "copy": "These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond Nike websites. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy & Cookie Policy"
        },
        {
          "block": "copy",
          "copy": ". De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "You can always change your preference by visiting the 'Cookie Settings' at the bottom of the page. View "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy & Cookie Policy"
      },
      {
        "block": "copy",
        "copy": " for full details."
      }
    ],
    "done": "Done",
    "bannerOk": "Ok"
  },
  "US": {
    "bannerLanguage": [
      {
        "block": "copy",
        "copy": "Nike processes information about your visit using cookies to improve site performance, facilitate social media sharing and offer advertising tailored to your interests. By continuing to browse our site, you agree to the use of these cookies. For more information see our "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy Policy"
      },
      {
        "block": "copy",
        "copy": ". You can adjust your preferences in "
      },
      {
        "block": "settings",
        "copy": "Cookie Settings"
      },
      {
        "block": "copy",
        "copy": "."
      }
    ],
    "cookieSettings": "Your Cookie Settings",
    "termsLink": "Terms of Use",
    "privacyLink": "Privacy & Cookie Policy",
    "functional": {
      "headline": "Functional",
      "copy": "These cookies are required for basic site functionality and are therefore always enabled. These include cookies that allow you to be remembered as you explore the site within a single session or, if you request, from session to session. They help make the shopping cart and checkout process possible as well as assist in security issues and conforming to regulations."
    },
    "performance": {
      "headline": "Performance",
      "copy": "These cookies allow us to improve the site’s functionality by tracking usage on this website. In some cases these cookies improve the speed with which we can process your request, allow us to remember site preferences you’ve selected. De-selecting these cookies may result in poorly-tailored recommendations and slow site performance."
    },
    "social": {
      "headline": "Social Media and Advertising",
      "copy": [
        {
          "block": "copy",
          "copy": "Social media cookies offer the possibility to connect you to your social networks and share content from our website through social media. Advertising cookies (of third parties) collect information to help better tailor advertising to your interests, both within and beyond Nike websites. In some cases, these cookies involve the processing of your personal data. For more information about this processing of personal data, check our "
        },
        {
          "block": "privacyLink",
          "copy": "Privacy & Cookie Policy"
        },
        {
          "block": "copy",
          "copy": ". De-selecting these cookies may result in seeing advertising that is not as relevant to you or you not being able to link effectively with Facebook, Twitter, or other social networks and/or not allowing you to share content on social media."
        }
      ]
    },
    "changePreference": [
      {
        "block": "copy",
        "copy": "You can always change your preference by visiting the 'Cookie Settings' at the bottom of the page. View "
      },
      {
        "block": "privacyLink",
        "copy": "Privacy & Cookie Policy"
      },
      {
        "block": "copy",
        "copy": " for full details."
      }
    ],
    "done": "Done",
    "bannerOk": "Ok"
  }
}