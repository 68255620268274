import React, { Component } from 'react';
import "./ArticleSocialShare.css?__remix_sideEffect__";
import AriaModal from 'react-aria-modal';
import { CSSTransition } from 'react-transition-group';
import * as ctc from 'react-copy-to-clipboard';
import * as rs from 'react-share';
import shareArrow from '../../images/Share_Arrow.svg';
import facebookIcon from '../../images/Social_Facebook_Black.svg';
import twitterIcon from '../../images/Social_Twitter_Black.svg';
import clipboardIcon from '../../images/clipboard.svg';

const { FacebookShareButton, TwitterShareButton } = rs;
// const FacebookShareButton = require('react-share').FacebookShareButton;
// const TwitterShareButton = require('react-share').TwitterShareButton;
// const CopyToClipboard = require('react-copy-to-clipboard').CopyToClipboard;
const { CopyToClipboard } = ctc;


export default class SocialShare extends Component {
  constructor(props) {
    super(props);
    this.state = { drawerOpen: false, modalOpen: false, link: '' };
  }
  componentDidMount() {
    this.setState({ link: window.location.href });
  }
  toggleDrawerOpen = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };
  toggleOverlayOpen = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };
  closeOverlay = () => {
    this.setState({ modalOpen: false });
  };
  render() {
    let link = this.state.link;

    return (
      <div className={`article-social-share border-color--${this.props.borderColor}`}>
        <div className="article-social-share__flex-parent" onClick={this.toggleDrawerOpen}>
          <p
            className="article-social-share__toggle date">

            <div className={`article-social-share__share-arrow icon icon-mask bg--${this.props.borderColor}`}
            style={{ maskImage: `url('${shareArrow}')`, WebkitMaskImage: `url('${shareArrow}')` }}>
            </div>
            share
          </p>
          {this.state.drawerOpen &&
          <CSSTransition
            in={this.state.drawerOpen}
            appear
            classNames="button-drawer"
            timeout={300}>

              <div
              className="article-social-share__btns"
              onClick={this.toggleOpen}>

                <FacebookShareButton className="article-social-share__btn" url={link} key="facebook">
                  <img src={facebookIcon} alt="" />
                </FacebookShareButton>
                <TwitterShareButton className="article-social-share__btn" url={link} key="twitter">
                  <img src={twitterIcon} alt="" />
                </TwitterShareButton>
              </div>
            </CSSTransition>}

        </div>
        {this.state.modalOpen &&
        <CopyLinkOverlay closeOverlay={this.closeOverlay} link={link} />}

      </div>);

  }
}

class CopyLinkOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      copied: false
    };
  }
  render() {
    return (
      <AriaModal
        titleText="Nike SB - Copy Link"
        initialFocus="#close"
        onExit={this.props.closeOverlay}
        underlayStyle={{ background: 'rgba(0, 0, 0, 0.8)' }}
        underlayClass="copy-link__parent"
        dialogClass="copy-link"
        dialogStyle={{
          display: 'flex',
          textAlign: 'center'
        }}>

        <img
          id="close"
          className="copy-link__close btn--close icon"
          src="/icons/nav/Video_CloseIcon.svg"
          onClick={this.props.closeOverlay}
          alt="close navigation button" />

        <h3 className="headline headline--alt">Copy Link</h3>
        <p className="copy-link__link-text eyebrow">
          <span>{this.props.link}</span>
        </p>
        <CopyToClipboard
          text={this.props.link}
          id={'copy'}
          onCopy={() => this.setState({ copied: true })}>

          <p className="btn copy-link__btn date">Copy</p>
        </CopyToClipboard>
        {this.state.copied ?
        <p className="copy-link__status date">Copied</p> :

        <p className="copy-link__status date" />}

      </AriaModal>);

  }
}