import React, { Component } from 'react';
import "./P2Carousel.css?__remix_sideEffect__";
import { Link } from 'react-router-dom';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css?__remix_sideEffect__";
import "slick-carousel/slick/slick-theme.css?__remix_sideEffect__";
import shuffleButton from '../../images/ShuffleButton.svg';

export const links = () => [
{ rel: "stylesheet", href: slickStyle },
{ rel: "stylesheet", href: slickTheme }];


let dragging = false;

class P2Asset extends Component {
  constructor(props) {
    super(props);
    this.state = { isOverlayOpen: false, hover: false, contentRect: {} };
  }

  openOverlay = () => {
    this.setState({ isOverlayOpen: true });
  };
  closeOverlay = () => {
    this.setState({ isOverlayOpen: false, hover: false });
  };
  toggleOverlay = () => {
    if (!dragging) {
      this.state.isOverlayOpen ? this.closeOverlay() : this.openOverlay();
    }
  };

  render() {
    let { asset } = this.props;

    return (
      <React.Fragment>
        <div className="p2__card-img" dangerouslySetInnerHTML={{ __html: asset ? asset.asset : '' }}></div>
      </React.Fragment>);

  }
}

class P2Card extends Component {
  handleClick = (e) => {
    if (dragging) {
      e.preventDefault();
    }
  };

  // These cards shouldn't need to rerender - this prevents the video cards from flickering on resize
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    let { title, textColor = 'black', backgroundColor, asset, slug } = this.props;

    let trackClickOptions = {
      module: {
        event_action: "Card Click",
        event_category: "P2 Carousel",
        event_label: `Clicked carousel card: ${title}`
      }
    };

    const ConditionalLink = ({ children, to, tracking, clickable }) => {
      return <Link data-track-click={tracking} onClickCapture={this.handleClick} to={to} className='p2__card p2__card--has-clickthrough'>{children}</Link>;
    };

    return (
      <ConditionalLink to={`/${slug}`} tracking={JSON.stringify(trackClickOptions.module)} clickable={true}>
        <div className="p2__card-headline">
          <h2
            className={`black headline headline--sm headline--highlight headline--highlight-${backgroundColor}`}>

            {title}
          </h2>
        </div>
        <P2Asset asset={asset} />
      </ConditionalLink>);

  }
}

export default class P2Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = { playing: false };
  }

  onScroll = () => {
    if (typeof window !== "undefined") {
      if (document.getElementById('p2-slider').getBoundingClientRect().top < 100) {
        let slides = document.getElementsByClassName('p2__card');
        for (let i = 0; i < slides.length; i++) {
          slides.item(i).classList.add('do-animation');
        }

        window.removeEventListener('scroll', this.onScroll, false);
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, { passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.preventTouch, { passive: false });
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e) {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }

  render() {
    let {
      headline,
      backgroundColor = 'blue',
      comics,
      randomLink
    } = this.props;

    let settings = {
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false,
      infinite: false,
      slidesToShow: 6,
      variableWidth: true,
      dots: true,
      appendDots: (dots) => <ul>{dots}</ul>,
      responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2
        }
      }]

    };

    // Analytics.
    let trackClickOptions = {
      shuffle: {
        event_action: "Shuffle",
        event_category: "P2 Carousel",
        event_label: "Clicked shuffle icon"
      }
    };

    return (
      <div id="p2-slider" className={`p2 p2--${backgroundColor}`}>
        <div className="p2__content">
          <h2 className="eyebrow p2__headline">{headline}</h2>
          <div className="p2__cards">
            <Slider.default {...settings} ref={(slider) => this.slider = slider}>
              {comics.map((component, i) => {
                const key = 'carouselCardSlider:' + i;
                return (
                  <P2Card
                    key={key}
                    {...component}>
                  </P2Card>);

              })}
              <React.Fragment>
                <div className="p2__shuffle">
                  <Link
                    to={randomLink}
                    data-track-click={JSON.stringify(trackClickOptions.shuffle)}>

                    <img src={shuffleButton} alt="Show me something" />
                  </Link>
                </div>
              </React.Fragment>
            </Slider.default>
          </div>
        </div>
      </div>);

  }
}